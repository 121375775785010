import staffOne from '../assets/gegobisco/cogebisco-staff-1.jpg'

export const links = [
  {
    id: 1,
    text: 'accueil',
    url: 'home',
  },
  {
    id: 2,
    text: 'qui sommes nous',
    url: 'about',
  },
]

export const services = [
  {
    id: 1,
    icon: staffOne,
    title: 'Secrétaire Général',
    text: 'PUNGWE NEMBA',
    descrtption: 'Premier Avocat Général près la Cour de cassation',
  },

]


